<template>
  <div class="container flex items-center justify-center">
    <template v-if="!userStore.isLoggedIn">
      <div
        class="flex flex-col md:flex-row relative columns mb-4 md:mb-10 mt-5 mx-0 shadow bg-white rounded-xl z-20 px-5 pt-8 pb-10 text-center"
      >
        <CommonLogin
          ref="login"
          v-model:email="input.email"
          v-model:password="input.password"
          :try-login="true"
        />
      </div>
    </template>
    <template v-else-if="userStore.isLoggedIn">
      <div class="grid gap-6 mb-16 text-center">
        <div
          class="flex flex-col md:flex-row relative columns mb-4 md:mb-10 mt-5 mx-0 shadow bg-white rounded-xl z-20 px-5 pt-8 pb-10 text-center"
        >
          <div class="px-5 label-m text-red">
            {{ $t('YouAreNowLoggedInWith') }} {{ userStore.user.email }}
          </div>
        </div>
        <NuxtLink
          :to="{
            name: 'frontpage',
          }"
        >
          <CommonButton bg="bg-blue" text="text-white">
            {{ $t('login.GoToHomepage') }}
          </CommonButton>
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const route = useRoute()
const requestUrl = useRequestURL()
const { sentry } = useSentry()

const userStore = useUserStore()

const input = ref({ email: '', password: '' })

const redirect =
  typeof route.query.redirect === 'string' ? route.query.redirect : ''

const {
  data: iterasPreAuth,
  error: preAuthError,
  refresh: refreshPreAuth,
} = await useIterasPreAuth()
if (preAuthError.value) {
  sentry?.captureException(
    new Error('Internal Server Error: ', {
      cause: preAuthError.value,
    })
  )
}

// Iteras redirect URL
const nextUrl = typeof route.query.next === 'string' ? route.query.next : ''
// Handle navigation
const handleNextNavigation = () => {
  if (iterasPreAuth.value) {
    return navigateTo(`${nextUrl}?iteraspreauth=${iterasPreAuth.value}`, {
      external: true,
    })
  }
}
// Initial navigation if logged in
if (userStore.isLoggedIn) {
  if (redirect) {
    await navigateTo({ name: redirect })
  }
  if (nextUrl) {
    await refreshPreAuth()
    await handleNextNavigation()
  }
}
// Watch for login state changes
watch(
  () => userStore.isLoggedIn,
  async (isLoggedIn) => {
    if (!isLoggedIn) return
    if (redirect) {
      return navigateTo({ name: redirect })
    }
    if (nextUrl) {
      await refreshPreAuth()
      return handleNextNavigation()
    }
  }
)

useHead(() => {
  // Have to use config.public.site here, due to composables not working in useHead
  const siteConfig = config.public.site

  return createBasicHeadMetaData(siteConfig, requestUrl)
})
</script>
